<template>
  <component :is="clientData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="clientData === undefined">
      <h4 class="alert-heading">Error fetching client data</h4>
      <div class="alert-body">
        No client found with this client id. Check
        <b-link class="alert-link" :to="{ name: 'clients-list' }">
          Client List
        </b-link>
        for other clients.
      </div>
    </b-alert>
    <client-edit-tab-information
      v-if="clientData"
      :groupcode-data="groupcodeData"
      :user-options="userOptions"
      :client-data="clientData"
      @refetch-data="refetchData"
      class="mt-2 pt-75"
    />
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import clientStoreModule from "../clientStoreModule";
// import ClientEditTabAccount from "./ClientEditTabAccount.vue";
import ClientEditTabInformation from "./ClientEditTabInformation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    // ClientEditTabAccount,
    ClientEditTabInformation,
  },
  setup() {
    const clientData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-client";

    const client = {
      id: 0,
      information:[],
      contacts: [
        {
        contact_name: '',
        contact_title: '',
        contact_phone: '',
        contact_email: '',
        id_number: '',
      }
      ],
    };

    
    const groupcodeData = ref([]);
    const userOptions = ref([]);

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "clients-create") {
        clientData.value = client;
      } else {
        store
          .dispatch("app-client/fetchClient", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            clientData.value = response.data.client;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              clientData.value = undefined;
            }
          });
      }

    /* 
      store
        .dispatch(CLIENT_GROUP_CODE_STORE_MODULE_NAME + "/fetchGroupCodes", {})
        .then((response) => {
          groupcodeData.value = response.data.groupcodes;
        //groupcodeData.value.unshift({id:null, group_code:' -- ', name_of_group: ''})
        //console.log('groupcodeData', this.groupcodeData.value) 
        }); */
        store.dispatch('app/fetchOptionList', {group_code: true, user: true})
            .then((response) => {
                
                groupcodeData.value = response.data.group_codes;
                userOptions.value = response.data.users;
            })
            .catch((error) => {
                console.log(error)
            })

    };

    refetchData();

    return {
      groupcodeData,
      clientData,
      userOptions,
      refetchData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
